import React from 'react'

const Step3 = ({setStep, data}) => {

    return(
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up</p>
                </div>
                <div className="header_btn">
                    <i className="fas fa-chevron-left btn-back-header" onClick={() => setStep(1)}></i>
                </div>
            </div>
            <div className="stepList">
                <p className="stepList_titleMain">Let’s get you started your home food business in few steps</p>
                <div className="stepList_right">
                    {data.map((item, index) => {
                        return(
                            <div className="stepList_body" key={index}>
                                <div className="stepList_body-top">
                                    <p className="stepList_title">Step - 0{index + 1}</p>
                                </div>
                                <p className="stepList_desc">{item.Title}</p>
                            </div>
                        )
                    })}
                    <div className="stepList_button">
                        <button className="btn-primary" onClick={() => setStep(3)}>Next</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step3
import React from 'react'
import cart from '../../assets/images/cart.png'

const Finish = ({setStep, setShowCongoPage}) => {
    
    return(
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up</p>
                </div>
            </div>
            <div className="summary">
                <div className="summary_img">
                    <img src={cart} alt=''/>
                <p className="summary_congrats">Congratulations!</p>
                </div>
                <p className="summary_desc"> You have successfully completed all the steps. Once our team review your credentials, you will be notified and you be able to start selling your food right away!</p>
                <div className="summary_btn">
                    <button className="btn-primary" onClick={() => {setStep(5); setShowCongoPage(false)}}>Summary</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Finish;
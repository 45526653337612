import React, {useState, useEffect} from "react";
import axios from "axios";
import WebIframe from "../modals/webIframe";
import instance from "../../instance";

const FoodList = ({setStep, id, stateName}) => {

    const [list, setList] = useState([])
    const [desc, setDesc] = useState('')
    const [objToSend, setObjToSend] = useState('')
    const [webModal, setWebModal] = useState(false)

    useEffect(() => {
        instance.get(`api/Chef?statId=${id}`)
        .then(res => {
            setList(res.data)
            setDesc(res.data.Desc)
        })
    }, [])

    const handleApi = () => {
        setStep(4)
    }

    return(
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up</p>
                </div>
                <div className="header_btn">
                    <i className="fas fa-chevron-left btn-back-header" onClick={() => setStep(2)}></i>
                </div>
            </div>
            <div className="list">
                <p className="stepList_titleMain">Let’s get you started your home food business in few steps</p>
                <div className="step_top">
                    <p className="step_title">Before you Start</p>
                </div>
                <p className="list_title">Know the Home Food Rules, laws and your rights</p>
                <p className="list_desc">{desc}</p>
                <p className="list_text">Cottage food list by {stateName} department of state.</p>
                <div className="list_body">
                    {list.Products && list.Products.map((item, index) => {
                        return(
                            <div key={index}>
                                <p className="list_body-heading">{item.CatName}</p>
                                <div className="list_body-main">
                                    {item.Products.map((item, index) => {
                                        return(
                                            <div className="list_body-point" key={index}>
                                                <i className="fas fa-check"></i>
                                                <p>{item.name}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <p className="list_text">{stateName} Department of State Health Services, Important links to review.</p>
                {/* {webModal && <WebIframe item={objToSend} setWebModal={setWebModal} />} */}
                {list.Links && list.Links.map((item, index) => {
                    return(
                        <div key={index}>
                            <p className="list_text2">{item.title}</p>
                            <div className="list_links">
                                <div><a href={item.link}>{item.link}</a></div>
                            </div>
                        </div>
                    )
                }) }
                <div className="accept">
                    <button className='btn-primary' onClick={() => {handleApi()}}>Next</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FoodList;
import React, { useState, useEffect } from 'react'
import { v4 as uuid4 } from 'uuid'
import { useForm } from 'react-hook-form'
import AWS from 'aws-sdk'
import axios from 'axios'

import File from '../../assets/images/file.PNG'
import IconFinger from '../../assets/images/finger.PNG'
import CrossIcon from '../../assets/images/icon2.PNG'
import WebIframe from '../modals/webIframe'
import instance from '../../instance'

const Step4 = ({ isSummary, setStep, data, vendorId, TotalIndex, stepIndex, setStepIndex, dataAll }) =>
{

    const { handleSubmit, register, reset } = useForm()
    const [fileName, setFileName] = useState('')
    const [expDate, setExpDate] = useState('')
    const [refDocuments, setRefDocuments] = useState([])
    const [allLinks, setAllLinks] = useState([])
    const [err, setErr] = useState('')
    const [webModal, setWebModal] = useState(false)
    const [url, setUrl] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [showfinish, setShowfinish] = useState(false)
    const [isProgress, setIsProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const [levels, setLevels] = useState([])

    useEffect(() =>
    {
        if (stepIndex === TotalIndex)
        {
            setShowfinish(true);
        }
        setFileName(data?.DocumentURL)
        setExpDate(data?.expiry && data?.expiry?.substring(0, 10))
    }, [stepIndex])
    console.log("first", expDate)
    useEffect(() =>
    {
        handleLevels()
        // if (isSummary)
        // {
        //     setShowfinish(true)
        // }
    }, [])
    // useEffect(() => {
    //     console.log("first")
    //     const index = dataAll.findIndex(item => item.StepNum === stepIndex);
    //     setOriginalIndex(index)
    //     console.log("heree=>>>>>>", index)
    // },[])
    useEffect(() =>
    {
        let array = []

        const multi = data?.RefLink?.split(",")
        multi?.forEach(elem =>
        {
            const temp = elem?.split("|")
            let obj = {}
            obj.title = temp[0]
            obj.link = temp[1]
            if (obj.title && obj.link)
            {
                array.push(obj)
            }
        });
        setAllLinks(array)
        // setRefDocuments(multi)
    }, [stepIndex])

    const isbuttonDisable = () =>
    {
        if (data?.DocumentRequired === 1)
        {
            if (isChecked && fileName && !isProgress && expDate)
            {
                return 'btn-primary'
            } else
            {
                return 'btn-disabled'
            }
        }
        else
        {
            if (isChecked && !isProgress)
            {
                return 'btn-primary'
            } else
            {
                return 'btn-disabled'
            }
        }
    }

    const handleLevels = () =>
    {
        const array = []
        for (let i = 1; i <= dataAll.length; i++)
        {
            array.push(i)
        }
        setLevels(array)
    }

    AWS.config.update({
        accessKeyId: 'AKIA3P2GE46R3IHOHEB4',
        secretAccessKey: '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'
    })

    const myBucket = new AWS.S3({
        params: { Bucket: 'foodostireqdocs' },
        region: 'us-east-2',
    })
    const handleFile = (e) =>
    {
        const file = e.target.files[0]
        if (file)
        {
            if (file.size < 5242880 && file.type === 'application/pdf')
            {
                setProgress(0)
                setIsProgress(true)
                const time = new Date()
                const temp = `${ data?.Title.replace(/ /g, '-') }`
                const newFile = `${ temp }${ time.toISOString() }-${ vendorId }`
                const final = `${ newFile.replace(/:/g, '-') }.pdf`
                setUrl(`https://foodostireqdocs.s3.us-east-2.amazonaws.com/${ final }`)
                const params = {
                    ACL: 'public-read',
                    Body: file,
                    Bucket: 'foodostireqdocs',
                    Key: final,
                };

                myBucket.putObject(params)
                    .on('httpUploadProgress', (evt) =>
                    {
                        const progress = Math.round((evt.loaded / evt.total) * 100)
                        setProgress(progress)
                        if (progress === 100)
                        {
                            setIsProgress(false)
                        }
                    })
                    .send((err) =>
                    {
                        if (err) console.log(err)
                    })

                setFileName(e.target.files[0].name)
                setErr('')
            }
            else
            {
                setErr('Only PDF with Maximum 5MB is Allowed');
            }
            e.target.value = null
        }
    }

    const handleApi = () =>
    {
        let uri = `api/Chef?vdrId=${ vendorId }&docUrl=${ url }&chefStateReq=${ data?.Id }&expiry=${ expDate }`;
        // console.log("====? url", uri)
        instance.put(uri)
            .then(res =>
            {
                increaseIndex()
                if (showfinish)
                {
                    setStep(5)
                }
            })
    }

    const removeFile = () =>
    {
        setFileName('')
    }

    const increaseIndex = () =>
    {
        if (stepIndex < (TotalIndex))
        {
            setStepIndex(stepIndex + 1)
            localStorage.setItem('index', parseInt(stepIndex) + 1)
        }
    }

    const onSubmit = async (values) =>
    {
        handleApi()
        setFileName('')
        setIsChecked(false)
        reset()
        localStorage.clear()
    }

    const downloadPdf = (e) =>
    {
        e.preventDefault()
    }

    const today = new Date();
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const minDate = tomorrow.toISOString().slice(0, 10);
    console.log("minDate", minDate)


    return (
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up </p>
                </div>
            </div>
            <div className="step">
                <p className="stepList_titleMain">Let’s get you started your home food business in few steps</p>
                <div className="levels">
                    {levels.map((item, index) =>
                    {
                        return (
                            <span key={index} className={index === (stepIndex) ? 'levels_active' : ((stepIndex) > index ? 'levels_done' : '')}>{index + 1}</span>
                        )
                    })}
                </div>
                <div className="step_right">
                    <div className="step_top">
                        <p className="step_title">Step - 0{stepIndex + 1}</p>
                    </div>
                    <p className="step_heading">{data?.Title}</p>
                    <p className="step_desc">{data?.Desc}</p>
                    {/* {webModal && <WebIframe item={objToSend} setWebModal={setWebModal} />} */}
                    {
                        allLinks && allLinks?.map((item) => (
                            <button className='step_btns'><a href={item.link}>{item?.title}</a></button>
                        ))
                    }
                    {/* {data?.RefLink && <React.Fragment>
                            <a href={data?.RefLink} className="step_link" target="_blank">{data?.RefLink}<img src={IconFinger}/></a>
                        </React.Fragment> } */}
                    {/* {
                        allLinks && allLinks?.map((item) => (
                            <button className='step_btns' onClick={() => {setObjToSend(item?.link); setWebModal(true)}}>{item?.title}</button>
                        ))
                    } */}
                    {data?.sampleDoc && <a href={data?.sampleDoc} download className="step_link"><i className="fas fa-download"></i> Download Document</a>}
                    <form onSubmit={handleSubmit(onSubmit)} >
                        {data?.DocumentRequired === 1 && <div className='step_expiry'>
                            <p>Choose Expiry</p>
                            <input type="date" value={expDate} min={minDate} onChange={(e) => setExpDate(e.target.value)} />
                        </div>}
                        {data?.DocumentRequired === 1 && <div className="step_file">
                            <input
                                type="file"
                                id="file"
                                {...register('file')}
                                accept="application/pdf"
                                onChange={(e) => { handleFile(e) }}
                            />
                            <label className="step_label" htmlFor="file">
                                <div className="label_left">
                                    <img src={File} alt="..." />
                                </div>
                                <div className="label_right">
                                    <p>{fileName ? "Document attached" : "Press to upload document"}</p>
                                    <span>{fileName ? `${ fileName.substring(0, 20) }...` : 'Upload a PDF Document'}</span>
                                </div>
                            </label>
                            {!err && <p className="step_instruction">Only PDF format with max file size of 5MB</p>}
                            <p className="step_error">{err}</p>
                            {isProgress && <p>{progress}% Uploaded</p>}
                            {!isProgress && fileName && <img className='step_cross' onClick={removeFile} src={CrossIcon} alt="" />}
                        </div>}
                        {/* {url && <div className="step_download"><a href={url}>Download</a></div>} */}
                        <div className="accept">
                            <div className="accept_left">
                                <input type="checkbox" id="check" value={isChecked} name='accepted' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                <label htmlFor="check">I have read the above details <br /> and I fullfill the requirements.</label>
                            </div>
                            <div className="accept_right">
                                {showfinish ? <button type='submit' className={isbuttonDisable()} onClick={async () => { await onSubmit() }}>Submit</button> : <button type='submit' className={isbuttonDisable()} >Next</button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step4
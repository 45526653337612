import React, {useState, useEffect} from "react";
import axios from "axios";
import instance from "../../instance";

const Step1 = ({setStep, setStepList, setId, vendorId, id, setStateName}) => {

    const [state, setState] = useState([])
    const [getClass, setGetClass] = useState('btn-disabled')

    
    useEffect(() => {
        instance.get('api/Chef').then(res => {
            setState(res.data)
        })
    }, [])
    
    const handleClass = (e) => {
        setGetClass('btn-primary')
    }
    
    const handleApi = () => {
        instance.put(`api/Chef?vendorId=${vendorId}&stateId=${id}`)
        .then (res => {
            instance.get(`api/Chef?stateId=${id}&vendorID=${vendorId}`)
            .then(res => {
                setStepList(res.data)
                setStep(2)
            })
        })
    }

    const handleChange = (e) => {
        setId(e.target.value)
        setStateName(e.target.selectedOptions[0].getAttribute('data-set'))
    }

    return(
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up</p>
                </div>
                <div className="header_btn">
                    <i className="fas fa-chevron-left btn-back-header" onClick={() => setStep(0)}></i>
                </div>
            </div>
            <div className="states">
                <p className="states_title">GOT A CHEF INSIDE YOU? OR ARE YOU A FARMER?</p>
                <p className="states_title2">Let’s make you more valuable</p>
                <p className="states_desc">Start selling your food and produce as easy as 1,2,3.</p>
                <p className="states_desc">We have made things easy for you.</p>
                <div className="states_body">
                    <div className="states_content">
                        <p>Please select your state.</p>
                        <select onChange={(e) => {handleChange(e); handleClass()}}>
                            <option disabled selected>Select</option>
                            {state.map((item, index) => {
                                return(
                                    <option key={index} value={item.Id} data-set={item.StateName}>{item.StateName}</option>
                                )
                            })}
                        </select>
                        <p>If your state is not listed, rest assured we are coming to you soon!</p>
                    </div>
                </div>
            </div>
            <div className="states_button">
                <button className={getClass} onClick={() => {handleApi()}}>Next</button>
            </div>
        </React.Fragment>
    )
}

export default Step1;
import React from "react";

import Bg from '../../assets/images/bg.jpg'

const Start = ({setStep}) => {
    return(
        <React.Fragment>
            <div className="start">
                <div className="start_box">
                    <img src={Bg} alt="..." />
                    <div className="start_titles">
                        <p className="start_title">Open your own home Restaurants</p>
                        <p className="start_title2">Connect to the world <br/> through food</p>
                    </div>
                </div>
                <div className="start_buttons">
                    <button className="start_btn" onClick={() => setStep(1)}>Get Started</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Start
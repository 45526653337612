import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';

import States from './components/states';
import FoodList from './components/food-list';
import StepList from './components/steps-list';
import Steps from './components/steps';
import Start from './components/start';
import Summary from './components/summary';
import Finish from './components/finish';
import instance from './instance';

function App()
{
    const [step, setStep] = useState(0)
    const [id, setId] = useState(0)
    const [stateName, setStateName] = useState('')
    const [currentStep, setCurrentStep] = useState('')
    const [showCongoPage, setShowCongoPage] = useState(true)
    const [stepList, setStepList] = useState([])
    const [vendorId, setVendorId] = useState()
    const [stepIndex, setStepIndex] = useState(0)
    const [loading, setLoading] = useState(true)
    const [isSummary, setIsSummary] = useState(false)

    useEffect(() =>
    {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const myParams = urlSearchParams.get('vendorId');
        setVendorId(myParams)
        instance.get(`api/Chef?vdId=${ myParams }`).then(res =>
        {
            setStateName(res.data.stateName)
            setId(res.data.stateid);
            // if (localStorage.getItem('index'))
            // {
            //     console.log('run');
            //     axios.get(`https://foodostibackend20200421091935.azurewebsites.net/api/Chef?stateId=${ res.data.stateid }&vendorID=${ myParams }`)
            //         .then(reas =>
            //         {
            //             setStepIndex(4)
            //             setStepList(reas.data)
            //             setId(res.data.stateid);
            //             setStep(4)
            //             setLoading(false)
            //         });
            // }
            // else 
            if (res.data.message && res.data.message == "Go to summary page")
            {
                instance.get(`api/Chef?stateId=${ res.data.stateid }&vendorID=${ myParams }`)
                    .then(reas =>
                    {
                        setCurrentStep(res.data.stepNo)
                        if (res.data.stepNo === 5)
                        {
                            setStepIndex(4)
                            setStep(4)

                        } else if (res.data.stepNo === 4)
                        {
                            setStepIndex(3)
                            setStep(4)
                        } else if (res.data.stepNo === 3)
                        {
                            setStepIndex(2)
                            setStep(4)
                        } else if (res.data.stepNo === 2)
                        {
                            setStepIndex(1)
                            setStep(4)
                        } else if (res.data.stepNo === 1)
                        {
                            setStep(2)
                        } else if (res.data.stepNo === 0)
                        {
                            setStep(5)
                            setShowCongoPage(false)
                        } else
                        {
                            setStepIndex(res.data.stepNo - 1)
                            setStep(4)
                        }
                        setStepList(reas.data)
                        setId(res.data.stateid);
                        // setstepindex is number of steps 0,1,2,3,4
                        // step screen number ( number 4 is for steps 0,1,2,3,4,5/5 )
                        // setStep(0)
                        setLoading(false)
                        setIsSummary(true)
                    });
            }
            else
            {
                setLoading(false)
            }
            // setLoading(false)
        })
    }, [])
    return (
        <React.Fragment>
            {loading && <div className='loader'><ReactLoading type={"bubbles"} color={"#992423"} width={200} height={200} /></div>}
            {(step === 0 && !loading) && <Start setStep={setStep} />}
            {step === 1 && <States
                setStep={setStep}
                setStepList={setStepList}
                setId={setId}
                id={id}
                vendorId={vendorId}
                setStateName={setStateName}
            />}
            {step === 2 && <StepList
                setStep={setStep}
                data={stepList}
            />}
            {step === 3 && <FoodList
                setStep={setStep}
                id={id}
                stateName={stateName}
            />}
            {stepList.filter((item, index) => index == stepIndex).map((item, index) =>
            {
                return (
                    step == 4 && <Steps
                        key={index}
                        isSummary={isSummary}
                        dataAll={stepList}
                        stepIndex={stepIndex}
                        TotalIndex={stepList.length - 1}
                        vendorId={vendorId}
                        data={item}
                        setStep={setStep}
                        setStepIndex={setStepIndex}

                    />
                )
            })}
            {step === 5 && <Summary
                setStep={setStep}
                id={id}
                stepIndex={stepIndex}
                setStepIndex={setStepIndex}
                vendorId={vendorId}
                stateName={stateName}
                stepList={stepList}
                showCongoPage={showCongoPage}
            />}
            {step === 6 && <Finish setStep={setStep} setShowCongoPage={setShowCongoPage} />}
        </React.Fragment>
    );
}

export default App;

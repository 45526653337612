import React, { useState, useEffect } from "react";
import axios from "axios";

import IconOkay from '../../assets/images/icon1.PNG'
import IconNotOkay from '../../assets/images/icon2.PNG'
import IconLoading from '../../assets/images/icon3.PNG'
import instance from "../../instance";

const Summary = ({ setStep, vendorId, id, setStepIndex, stateName, stepList, showCongoPage }) =>
{
    const [list, setList] = useState([]);

    const checkStatus = (index, status) =>
    {
        if (status === 0 || status === 3 || status === 5)
        {
            setStep(4)
            setStepIndex(index)
        }
    }

    const closescreen = () =>
    {
        localStorage.clear()
        setStep(6)
    }

    useEffect(() =>
    {
        instance.get(`api/Chef?stateId=${ id }&vendorId=${ vendorId }`)
            .then(res =>
            {
                setList(res.data);
            })
    }, [])
    //     useEffect(() => {
    //   setStepIndex(5)
    //     }, [])

    console.log("list", list)
    return (
        <React.Fragment>
            <div className="header">
                <div className="header_body">
                    <p className="header_desc">Setting Up</p>
                </div>
            </div>
            <div className="summary">
                <div className="summary_right">
                    <p className="summary_mainTitle">Registration Summary</p>
                    <p className="summary_stateTitle">State: {stateName}</p>
                    <div className="summary_inner">
                        {list.length !== 0 ? list?.map((item, index) =>
                        {
                            return (
                                <div className="summary_body" key={index}>
                                    <div className="summary_icon">
                                        <img src={(item?.ApprovalStatus === 0 || item?.ApprovalStatus === 3 || item?.ApprovalStatus === 5) ? IconNotOkay : (item?.ApprovalStatus === 1 || item?.ApprovalStatus === 2) ? IconLoading : IconOkay} alt="..." />
                                    </div>
                                    <div className="summary_text">
                                        <div className="summary_top">
                                            <div onClick={() => checkStatus(index, item?.ApprovalStatus)}>
                                                <p className="summary_title">Step - {item?.StepNum}</p>
                                                {item?.ApprovalStatus === 0 && <div className="summary_status resubmit">Not Submit</div>}
                                                {item?.ApprovalStatus === 1 && <div className="summary_status approve">Submitted/Not approved</div>}
                                                {item?.ApprovalStatus === 2 && <div className="summary_status approve">In review / pending</div>}
                                                {item?.ApprovalStatus === 3 && <div className="summary_status approve">Rejected - {item?.reason}</div>}
                                                {item?.ApprovalStatus === 4 && <div className="summary_status approve">Approved</div>}
                                                {item?.ApprovalStatus === 5 && <div className="summary_status approve">Expired</div>}

                                            </div>
                                        </div>
                                        <div className="summary_bot">
                                            <p>{item?.Title}</p>
                                            {item?.expiry && <p>Expiry: <span>{item?.expiry?.slice(0, 10)}</span></p>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : "No List Data"}
                    </div>
                    <div className="summary_bottom">
                        <h4>Start Selling from your Kitchen..</h4>
                        {showCongoPage &&
                            <div>
                                <button className="btn-primary" onClick={() => closescreen()}>Finish</button>
                            </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Summary;